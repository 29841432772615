
import ApiRequestService from '@/services/ApiRequestService'
import SnippetsService from '@/services/SnippetsService'
import StorageService, { LANGUAGE_KEY, USER_ID } from '@/services/StorageService'
import Multiselect from "vue-multiselect";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";
import OrganizationsUtil from "@/utils/OrganizationsUtil";
import PaginatedSearchListUtil from "@/utils/PaginatedSearchListUtil";
import ValidateUtil from "@/utils/ValidateUtil";
import DateUtil from '@/utils/DateUtil'
import * as moment from "moment";
import * as momentTimezone from "moment-timezone";

const TIMEZONE = 'Europe/Rome';

export default {

  components: {
    Multiselect
  },

  data() {
    let snippets = SnippetsService.getSnippetsByNamespaceAndLanguage( 'Events', StorageService.getFromStorage( LANGUAGE_KEY ) )

    return {
      status: 'all',
      page: 0,
      pageCount: 0,
      itemsPerPage: 10,
      eventsSnippet: snippets ?? {},
      eventsList: [],
      paginatedEventsList: [],
      paginatedSearchedEventsList: [],
      eventPaginatedList: [],
      searchedEventsList: [],
      searchedEventsPaginatedList: {},
      searchedData: [],
      loadedPages: {},
      searchKey: '',
      searchField: '',
      dialog: false,
      dialogDelete: false,
      dialogError: false,
      submitted: false,
      errorMessage: '',
      editedIndex: -1,
      name: '',
      description: '',
      isPublic: false,
      hasChildren: false,
      eventCapableFacilityList: [],
      selectedFacility: '',
      selectedSpace: '',
      facilities: [],
      facilitySpaces: [],
      selectedDate: '',
      selectedTime: '',
      availableDates: [],
      workingDays: [],
      specialTimeRequest: '',
      times: [],
      date: '',
      nowDate: new Date().toISOString().slice( 0, 10 ),
      modal: false,
      menu2: false,
      dates: [],
      selectedUser: '',
      selectedOrganization: '',
      organizations: [],
      allUsers: [],
      users: [],
      editedItem: {
        id: '',
        uuid: '',
        name: '',
        isPublic: false,
        description: '',
        bookingDate: '',
        startTime: '',
        endTime: '',
        userId: '',
        userName: '',
        spaceId: '',
        organizationId: '',
        status: '',
      },
      defaultItem: {
        id: '',
        uuid: '',
        name: '',
        isPublic: false,
        description: '',
        bookingDate: '',
        startTime: '',
        endTime: '',
        userName: '',
        userId: '',
        facilityId: '',
        spaceId: '',
        facilityName: '',
        spaceName: '',
        organizationId: '',
        status: '',
        organizationName: '',
        organizationCredits: 0,
        spaceCredits: 0,
      },
      sortBy: 'bookingDate',
      sortDesc: true,
      errorKey: '',
      searchedEventsOriginal: '',
      pagesCount: 1,
      currentPage: 1,
      disableKeyField: true,
      eventData: [],
      isAllowed: StorageService.getUserRoles() !== 'FacilityManager',
      errors: {
        nameError: '',
        facilityError: '',
        spaceError: '',
        dateError: '',
        timeError: '',
        organizationError: '',
        userError: '',
      }
    }
  },

  computed: {

    formTitle() {
      return this.editedIndex === -1 ? this.eventsSnippet.add_event : this.eventsSnippet.edit_event;
    },

    headers() {
      const header = [
        { text: this.eventsSnippet.id, value: 'id', width: '0px', sortable: true },
        {
          text: this.eventsSnippet.facilityName,
          value: 'facilityName',
          width: '0px',
          sortable: true
        },
        {
          text: this.eventsSnippet.spaceName,
          value: 'spaceName',
          width: '0px',
          sortable: true
        },
        { text: this.eventsSnippet.name, value: 'name', width: '0px', sortable: true },
        {
          text: this.eventsSnippet.organization,
          value: 'organizationName',
          width: '0px',
          sortable: true
        },
        {
          text: this.eventsSnippet.creditsCost,
          value: 'organizationCredits',
          width: '0px',
          sortable: false
        },
        {
          text: this.eventsSnippet.description,
          value: 'description',
          width: '0px',
          sortable: true
        },
        { text: this.eventsSnippet.isPublic, value: 'isPublic', width: '0px', sortable: true },
        {
          text: this.eventsSnippet.bookingDate,
          value: 'bookingDate',
          width: '0px',
          sortable: true
        },
        { text: this.eventsSnippet.startTime, value: 'startTime', width: '0px', sortable: true },
        { text: this.eventsSnippet.endTime, value: 'endTime', width: '0px', sortable: true },
        { text: this.eventsSnippet.userName, value: 'userName', width: '0px', sortable: true },
        { text: this.eventsSnippet.status, value: 'status', width: '0px', sortable: true },
      ];

      if ( this.isAllowed ) {
        header.push( {
          text: this.eventsSnippet.actions,
          value: 'actions',
          width: '0px',
          sortable: false
        } )
      }

      return header;
    },

  },

  watch: {
    dialog( val ) {
      val || this.close()
    },

    dialogDelete( val ) {
      val || this.closeDelete()
    },

    dialogError( val ) {
      val || this.closeErrorDialog()
    },

    async searchKey( searchText ) {
      if ( searchText ) {
        this.search$.next( searchText );
        this.searchField = searchText
      } else {
        this.searchField = ''
        this.searchedData = [];
        this.search$.next( '' );
      }
    },

  },

  async mounted() {
    this.validateUtil = new ValidateUtil( this.errors );

    this.initPaginatedDataUtil();
    await this.getDataList();

    await this.getFacilitiesFromApi();
    this.organizationsUtil = new OrganizationsUtil( 'api/admin-organizations', this.organizations, this.selectOrganization );
    await this.organizationsUtil.getOrganizations();

    this.loading = false;
    this.searchAction();
    this.setOpacityToNotActiveBookings();
  },

  methods: {
    getTimeName(time) {
      const startsInMorning = time?.match(/^(07|08|09|10|11)/gm)
      const endsInEvening = time?.match(/-(17|18|19|20|23):.+$/gm)

      if (startsInMorning && !endsInEvening) {
        return 'Morning'
      }

      if (!startsInMorning && endsInEvening) {
        return 'Afternoon'
      }

      return time ? 'All day' : ''
    },

    getTableFormattedDate( date ) {
      return DateUtil.getToLocaleDate( date )
    },

    setOpacityToNotActiveBookings() {
      setTimeout( () => {
        const isNotActives = document.querySelectorAll( '.isNotActive' );
        const isActives = document.querySelectorAll( '.isActive' );

        if ( isNotActives && isNotActives.length ) {
          for ( let isNotActive of isNotActives ) {
            isNotActive.closest( 'tr' ).style = 'opacity: 0.7';
          }
        }
        if ( isActives && isActives.length ) {
          for ( let isActive of isActives ) {
            isActive.closest( 'tr' ).style = 'opacity: 1';
          }
        }
      }, 300 )
    },

    initPaginatedDataUtil() {
      this.paginatedSearchListUtil = new PaginatedSearchListUtil( `api/admin-facility-events`, this.itemsPerPage, 'bookingDate', true );
      this.paginatedSearchListUtil.setDataUniqueField( 'id' );
      this.paginatedSearchListUtil.setDataModel( this.defaultItem );
    },

    updateListData() {
      this.eventData = this.paginatedSearchListUtil.entityData;
      this.pagesCount = this.paginatedSearchListUtil.pagesCount;
      this.searchedEventsOriginal = this.paginatedSearchListUtil.searchedDataOriginal;
      this.paginatedSearchedEventsList = this.paginatedSearchListUtil.paginatedSearchedEntityList;
      this.paginatedEventsList = this.paginatedSearchListUtil.paginatedEntitiesList;
      this.eventsList = this.paginatedSearchListUtil.entityList;
      this.setOpacityToNotActiveBookings();
    },

    customSort( items ) {
      return items;
    },

    getToLocaleTime( bookingDate, time ) {
      return DateUtil.getItalianTimeFromUTC( moment( bookingDate ).format( 'YYYY-MM-DD' ) + ' ' + time?.substr( 0, 5 ) )
    },

    async sortTable() {
      await Promise.resolve();
      await this.paginatedSearchListUtil.setSortColumn( this.sortBy, this.sortDesc );
      this.updateListData();
    },

    async statusChange( status ) {
      await this.paginatedSearchListUtil.setAdditionalParam( 'status', status );
      this.currentPage = 1;

      if ( this.searchField === '' ) {
        await this.paginatedSearchListUtil.getListData( this.currentPage );
        this.updateListData();

        return;
      }

      await this.paginatedSearchListUtil.getSearchedEntitiesPaginated( this.searchField, this.currentPage );
      this.updateListData();
    },

    async getDataList() {
      await this.paginatedSearchListUtil.getListData( this.currentPage );
      this.updateListData();
    },

    allowedDates( val ) {
      return this.workingDays.includes( new Date( val ).getDay() ) ? val : null
      if ( this.dates.length ) {
        return this.dates;
      }

      return new Date();
    },

    isEventEditable( item ) {
      let bookingDate = new Date( `${ item.bookingDate }` );
      let bookingTime = new Date( `${ bookingDate.getFullYear() }-${ bookingDate.getMonth() + 1 }-${ bookingDate.getDate() } ${ item.startTime }` );

      return !( item.status === 'finished' || item.status === 'canceled' || bookingTime.getTime() < Date.now() );
    },

    searchAction() {
      this.search$ = new Subject();
      this.search$.pipe(
        map( text => text?.toString()?.toLowerCase() ),
        debounceTime( 1000 ),
        distinctUntilChanged()
      ).subscribe( async ( searchText ) => {
        this.currentPage = 1;

        if ( this.searchField === '' ) {
          await this.paginatedSearchListUtil.getListData( this.currentPage );
          this.updateListData();
          return;
        }

        await this.paginatedSearchListUtil.getSearchedEntitiesPaginated( searchText, this.currentPage );
        this.updateListData();
      } )
    },

    async changePage( page ) {
      this.currentPage = page;
      await this.paginatedSearchListUtil.changePage( this.searchField, page );
      this.updateListData();
    },

    async getFacilitiesFromApi() {
      let facilityData = await ApiRequestService.getRequest( `api/admin-facility-spaces/event-capable` );
      this.setFacilitiesList( facilityData.length ? facilityData : [] );
    },

    setFacilitiesSpaces( facilityId ) {
      for ( let facilityData of this.eventCapableFacilityList ) {
        if ( facilityData.facility.id === facilityId ) {
          this.setFacilitySpacesList( facilityData.spaces );
          break;
        }
      }
    },

    async selectFacility( facility ) {
      this.selectedFacility = facility;
      this.selectedSpace = '';
      this.selectedDate = '';
      this.selectedTime = '';
      this.date = '';

      this.setFacilitiesSpaces( facility.id );
      this.isDataValid();
    },

    async selectSpace( space ) {
      this.selectedSpace = space;
      this.selectedDate = '';
      this.selectedTime = '';
      this.date = '';
      await this.onDateChange(this.date);
      this.isDataValid();
    },

    async getFacilitiesSpacesAvailableDatesApi( date = null ) {
      this.dates = [];
      this.availableDates = [];
      let spaceId = this.selectedSpace.id;
      let facilityId = this.selectedFacility.id;

      let today = date ? new Date( date ) : new Date();
      let year = today.getFullYear()
      let month = today.getMonth() + 1;
      month = month < 10 ? `0${ month }` : month;

      let availableDatesData = await ApiRequestService.getRequest( `api/admin-facility-events/${ facilityId }/space/${ spaceId }/event/schedule/${ year }-${ month }` );
      this.setFacilityDatesList( availableDatesData );
    },

    getFormattedDateString( date ) {
      if ( !( date instanceof Date ) ) {
        date = new Date( date );
      }

      let getYear = date.toLocaleDateString( 'default', { year: "numeric" } );
      let getMonth = date.toLocaleDateString( 'default', { month: "2-digit" } );
      let getDay = date.toLocaleDateString( 'default', { day: "2-digit" } );

      return `${ getYear }-${ getMonth }-${ getDay }`;
    },

    setFacilityDatesList( datesData ) {
      let today = new Date();
      let alreadyScheduledData = {};
      let eventAllowedTimeRanges = datesData.eventAllowedTimeRanges;
      this.workingDays = datesData?.workingDays?.map( item => item + 1 );

      for ( let [ key, value ] of Object.entries( eventAllowedTimeRanges ) ) {
        value?.map( item => {
          item.startTime = DateUtil.getItalianTimeFromUTC( moment(new Date(key)).format( 'YYYY-MM-DD' ) + ' ' + item.startTime?.substr( 0, 5 ) );
          item.endTime = DateUtil.getItalianTimeFromUTC( moment(new Date(key)).format( 'YYYY-MM-DD' ) + ' ' + item.endTime?.substr( 0, 5 ) );
          return item;
        } )
      }

      for ( let date in datesData.schedule ) {
        let filteredBusyDates = datesData.schedule[date].filter( item => {
          return item.facilityName && item.spaceName
        } );

        if ( !filteredBusyDates.length ) {
          continue;
        }

        alreadyScheduledData[date] = filteredBusyDates;
      }

      for ( let date in eventAllowedTimeRanges ) {
        let dateTime = new Date( date );

        if (dateTime.getMonth() === today.getMonth() && dateTime.getDate() < today.getDate()) {
            continue;
        }

        let currentDayTimesItem = eventAllowedTimeRanges[date];
        let formattedDate = this.getFormattedDateString( date );

        currentDayTimesItem.map( item => item.name = `${ item.startTime }-${ item.endTime }` );

        if ( alreadyScheduledData.hasOwnProperty( date ) ) {
          let scheduledItem = alreadyScheduledData[date];
          let existingIndexes = new Set();
          scheduledItem.forEach( scheduledTimeItem => {
            scheduledTimeItem.startTime = DateUtil.getItalianTimeFromUTC( moment().format( 'YYYY-MM-DD' ) + ' ' + scheduledTimeItem.startTime?.substr( 0, 5 ) );
            scheduledTimeItem.endTime = DateUtil.getItalianTimeFromUTC( moment().format( 'YYYY-MM-DD' ) + ' ' + scheduledTimeItem.endTime?.substr( 0, 5 ) );
            for ( let itemIndex in currentDayTimesItem ) {
              let timeItem = currentDayTimesItem[itemIndex];
              if ( timeItem.startTime !== scheduledTimeItem.startTime && timeItem.endTime !== scheduledTimeItem.endTime ) {
                continue
              }
              existingIndexes.add( itemIndex );
            }
          } );


          let available = [];
          let keys = Object.keys( currentDayTimesItem );
          [ ...existingIndexes ].forEach( item => {
            keys = keys.filter( val => val !== item )
          } )

          for ( let k of keys ) {
            available.push( currentDayTimesItem[k] );
          }
          currentDayTimesItem = available
        }
        currentDayTimesItem = currentDayTimesItem.flatMap( item => item.name );
        this.dates.push( formattedDate );

        this.availableDates.push( {
          date: formattedDate,
          time: currentDayTimesItem,
        } );
        if ( this.date ) {
          this.changeDate( this.date )
        }
      }
    },

    addEventClick() {
      this.disableKeyField = false
    },
    selectUser( val ) {
      this.selectedUser = val;

      this.isDataValid();
    },

    changeDate( date ) {
      this.date = date;
      this.selectedDate = date;
      this.selectedTime = '';
      this.times = [];
      let selectedDate = this.availableDates.filter( item => {
        return `${ item.date }` === `${ date }`
      } );

      if ( selectedDate.length ) {
        this.times = selectedDate[0].time;
      }

    },

    onDateChange(date){
      if(date){
        this.getFacilitiesSpacesAvailableDatesApi( date );
      }else{
        this.getFacilitiesSpacesAvailableDatesApi( null );
      }
    },

    selectTime( val ) {
      this.selectedTime = val;
      this.isDataValid();
    },

    changePublic( val ) {
      this.isPublic = val;
    },

    async selectOrganization( val ) {
      this.selectedOrganization = val;
      await this.organizationsUtil.getOrganizationUsers( this.selectedOrganization, this.setUsersList );

      this.isDataValid();
    },

    setUsersList( usersData ) {
      this.users = usersData;
    },


    getObjClone( obj ) {
      return JSON.parse( JSON.stringify( obj ) );
    },

    deleteItem( item ) {
      let list = this.searchField ? this.paginatedSearchedEventsList : this.paginatedEventsList;
      this.editedIndex = list.findIndex( fc => fc.id === item.id );
      this.editedItem = this.getObjClone( list[this.editedIndex] );
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      let deleteUrl = `api/admin-facility-events/${ this.editedItem.facilityId }/space/${ this.editedItem.spaceId }/booking/${ this.editedItem.uuid }/user/${ this.editedItem.userId }`;
      let item = await ApiRequestService.deleteRequest( deleteUrl );

      if ( item.hasOwnProperty( 'status' ) && item.status === 200 ) {
        this.eventData.data.forEach( item => {
          if ( item.id === this.editedItem.id ) {
            item.status = 'canceled';
          }
        } )

        await this.paginatedSearchListUtil.updateData( this.eventData );
        this.updateListData();

        this.closeDelete();
        this.close();
        return;
      }

      this.closeDelete();
      this.close();
      if ( item.errors ) {
        this.showErrorDialog( item.errors );
      } else {
        this.showErrorDialog( item.message );
      }
    },

    showErrorDialog( message ) {
      this.dialogError = true;
      this.errorMessage = message;
    },

    openDetails( item ) {
      if ( item && item.id ) {
        this.$router.push( `event-detail/${ item.uuid }` );
      }
    },

    setFacilitiesList( facilitiesData ) {
      this.facilities = [];

      facilitiesData?.forEach( item => {
        if ( item.facility ) {
          this.facilities.push( {
            id: item.facility.id,
            name: item.facility.name,
          } )
        }
      } );

      this.eventCapableFacilityList = facilitiesData;
    },

    setFacilitySpacesList( spaceData ) {
      this.facilitySpaces = [];
      this.spaceChildren = [];
      this.hasChildren = false;

      spaceData?.forEach( item => {
        this.facilitySpaces.push( {
          id: item.id,
          name: item.name,
          img: item.photos[0]?.url,
          children: item.childrenSpaces ?? []
        } )
      } );
    },

    async save() {
      this.submitted = true;

      if ( !this.isDataValid() ) {
        return;
      }

      await this.saveEvent();
    },

    getValidators() {
      return [
        { method: 'isRequiredFieldValid', errorKey: 'nameError', value: this.name },
        { method: 'isRequiredFieldValid', errorKey: 'facilityError', value: this.selectedFacility },
        { method: 'isRequiredFieldValid', errorKey: 'spaceError', value: this.selectedSpace },

        { method: 'isRequiredFieldValid', errorKey: 'dateError', value: this.selectedDate },
        { method: 'isRequiredFieldValid', errorKey: 'timeError', value: this.selectedTime },
        { method: 'isRequiredFieldValid', errorKey: 'userError', value: this.selectedUser },
        {
          method: 'isRequiredFieldValid',
          errorKey: 'organizationError',
          value: this.selectedOrganization
        },

      ]
    },

    isDataValid() {
      if ( !this.submitted ) {
        return;
      }

      this.validateUtil.setValidators( this.getValidators() );
      return this.validateUtil.isDataValid();
    },

    async saveEvent() {
      let selectedFacility = this.selectedFacility.id;
      let selectedSpace = this.selectedSpace.id;

      let requestUrl = `api/admin-facility-events/${ selectedFacility }/space/${ selectedSpace }/user/${ this.selectedUser.userId }`;
      let result = await ApiRequestService.postRequest( requestUrl, this.getDataModel() );

      if ( result.id ) {
        location.reload();
        return;
      }
      if ( result.errors ) {
        this.showErrorDialog( result.errors );
      } else {
        this.showErrorDialog( result.message );
      }
    },

    getDataModel() {
      let selectedTime = this.selectedTime.split( '-' );

      return {
        name: this.name,
        description: this.description,
        specialTimeRequest: this.specialTimeRequest,
        isPublic: this.isPublic,
        booking: {
          bookingDate: momentTimezone.tz( this.selectedDate, TIMEZONE ).format( 'YYYY-MM-DD' ),
          startTime: momentTimezone.tz( this.selectedDate + ' ' + selectedTime[0]?.substr( 0, 5 ), TIMEZONE ).valueOf(),
          endTime: momentTimezone.tz( this.selectedDate + ' ' + selectedTime[1]?.substr( 0, 5 ), TIMEZONE ).valueOf()
        }
      };
    },

    getParticipants() {
      let participants = [];

      this.selectedParticipants.forEach( item => {
        participants.push( item.email );
      } );

      this.selectedNewParticipants.forEach( item => participants.push( item ) );

      return participants.length ? participants : null;
    },

    close() {
      this.dialog = false;
      this.submitted = false;
      this.errorKey = '';
      this.selectedFacility = '';
      this.selectedSpace = '';
      this.date = '';
      this.name = '';
      this.description = '';
      this.isPublic = false;
      this.specialTimeRequest = '';
      this.selectedDate = '';
      this.selectedTime = '';
      this.selectedOrganization = '';
      this.selectedUser = '';
      this.facilitySpaces = [];
      this.dates = [];
      this.times = [];
      this.users = [];
      this.availableDates = [];

      /**
       * if action is edit, set to form old data
       */
      let data;
      if ( this.editedIndex !== -1 ) {
        data = this.getObjClone( this.editedItem );
      } else {
        data = this.getObjClone( this.defaultItem );
      }

      this.$nextTick( () => {
        this.editedItem = { ...this.getObjClone( this.defaultItem ) };
        this.editedIndex = -1;
      } )
    },

    closeDelete() {
      this.dialogDelete = false;
      this.submitted = false;

      this.$nextTick( () => {
        this.editedItem = { ...this.getObjClone( this.defaultItem ) };
        this.editedIndex = -1;
      } )
    },

    closeErrorDialog() {
      this.dialogError = false;
    },


  }
}


